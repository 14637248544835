import React from 'react';

import TableCell from './TableCell';

/**
 * Component for a table cell with a checkmark.
 *
 * @component
 * @param  disabled - used to set if table cell is disabled
 */
export default function CheckmarkTableCell({ disabled }) {
	return <TableCell positive={!disabled} disabled={disabled} icon="checkmark" />;
}
