import axios from 'axios';

const env = process.env.NODE_ENV;
const hostName = window.location.hostname;

const getUrl = () => {
	if (env === 'production') {
		return window.location.origin;
	} else {
		return 'http://' + hostName + ':4481';
	}
};

const axiosInstance = axios.create({
	baseURL: getUrl(),
	responseType: 'json',
	timeout: 10000,
});

// declare a response interceptor
axiosInstance.interceptors.response.use(
	response => response,
	error => {
		return Promise.reject(error);
	},
);

// declare a request interceptor
axiosInstance.interceptors.request.use(config => {
	return config;
});

/**
 * Wrapper fuer ein GET in axios
 * @param  {String} path   Der Pfad zur Resource
 * @param  {Object} header Objekt mit den Custom Headern
 * @return {Promise}       Den axios Promise
 */
export function get(path, header = {}) {
	return axiosInstance.get(path, header);
}

/**
 * Wrapper fuer ein POST in axios
 * @param  {String} path    Der Pfad zur Resource
 * @param  {Object} payload Das Objekt das bei der Anfrage an die Resource mitgesendet werden soll
 * @param  {Object} header  Objekt mit den Custom Headern
 * @return {Promise}        Den axiso Promise
 */
export function post(path, payload, header = {}) {
	return axiosInstance.post(path, payload, header);
}

/**
 * Wrapper fuer ein PATCH in axios
 * @param  {String} path    Der Pfad zur Resource
 * @param  {Object} payload Das Objekt das bei der Anfrage an die Resource mitgesendet werden soll
 * @param  {Object} header  Objekt mit den Custom Headern
 * @return {Promise}        Den axiso Promise
 */
export function patch(path, payload, header = {}) {
	return axiosInstance.patch(path, payload, header);
}

/**
 * Wrapper fuer ein PUT in axios
 * @param  {String} path    Der Pfad zur Resource
 * @param  {Object} payload Das Objekt das bei der Anfrage an die Resource mitgesendet werden soll
 * @param  {Object} header  Objekt mit den Custom Headern
 * @return {Promise}        Den axiso Promise
 */
export function put(path, payload, header = {}) {
	return axiosInstance.put(path, payload, header);
}

/**
 * Wrapper fuer ein DELETE in axios
 * @param {String} path Der Pfad zur Resource
 * @param {Object} header Objekt mit den Custom Headern
 * @return {Promise} Den axios Promise
 */
export function del(path, header = {}) {
	return axiosInstance.delete(path, header);
}
