import React from 'react';
import { Header, Grid } from 'semantic-ui-react';

import Loading from './Loading';
import IconButton from './IconButton';

import '../../css/custompanel.css';
import { translateComp } from '../../i18n/utils';
import { isFunction } from '../../misc/utils';

export default function CustomPanel({ children, title, isLoading = false, rightContent, onBackClick }) {
	const RightContent = rightContent;

	return (
		<div className="panelContainer">
			<Loading show={isLoading} />
			<Grid>
				<Grid.Row>
					<Grid.Column width={2}>{isFunction(onBackClick) && <IconButton basic={true} icon="arrow left" text={translateComp('common.back')} onClick={onBackClick} />}</Grid.Column>
					<Grid.Column width={12}>
						<Header as="h2">
							<div className="panelHeader">
								<div className="panelHeaderText">{title}</div>
								{rightContent && (
									<div className="panelHeaderRightContent">
										<RightContent />
									</div>
								)}
							</div>
						</Header>
					</Grid.Column>
					<Grid.Column width={2}></Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={16}>
						<div className="panelChildren">{children}</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	);
}
