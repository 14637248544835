import React from 'react';

import TableCell from './TableCell';

/**
 * Component for a table cell with a crossmark.
 *
 * @component
 * @param  disabled - used to set if table cell is disabled
 */
export default function CrossmarkTableCell({ disabled }) {
	return <TableCell negative={!disabled} disabled={disabled} icon="close" />;
}
