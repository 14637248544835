import { isFunction } from './utils';
import * as Toast from './toastHelper';
import { TOASTIFY_SEVERITY } from './const';

const Database_Name = 'KikiDB';
const Object_Store = 'files';

class Singleton {
	DB_Version = 1;

	initDB(callback) {
		let openRequest = indexedDB.open(Database_Name, this.DB_Version);

		// triggers if the client had no database
		openRequest.onupgradeneeded = function () {
			const db = openRequest.result;

			if (!db.objectStoreNames.contains(Object_Store)) {
				// if there's no "uploadfiles" store
				db.createObjectStore(Object_Store, { keyPath: 'id' });
			}
		};

		openRequest.onerror = function () {
			console.error('Error', openRequest.error);
		};

		openRequest.onsuccess = function () {
			const db = openRequest.result;
			// continue working with database using db object
			db.onversionchange = function () {
				db.close();
				Toast.show('Database is outdated, please reload the page.', TOASTIFY_SEVERITY.WARNING);
			};

			if (isFunction(callback)) callback(db);
		};

		openRequest.onblocked = function () {
			// this event shouldn't trigger if we handle onversionchange correctly
			// it means that there's another open connection to the same database
			// and it wasn't closed after db.onversionchange triggered for it
		};
	}

	addFileTransaction(file) {
		const transactionCallback = db => {
			let transaction = db.transaction(Object_Store, 'readwrite');

			transaction.onerror = function () {
				console.log('Add File Error', transaction.error);
			};

			let request = transaction.objectStore(Object_Store).add(file);

			request.onerror = function (event) {
				// ConstraintError occurs when an object with the same id already exists
				if (request.error.name === 'ConstraintError') {
					Toast.show('File with such id already exists', TOASTIFY_SEVERITY.WARNING);
					event.preventDefault(); // don't abort the transaction
					event.stopPropagation(); // don't bubble error up, "chew" it
				} else {
					// unexpected error, can't handle it
					// the transaction will abort
					// we can take care of error in transaction.onabort
				}
			};

			transaction.onabort = function () {
				console.log('Error', transaction.error);
			};

			transaction.oncomplete = function () {
				// console.log('Transaction is complete');
			};
		};

		this.initDB(transactionCallback);
	}

	getAllFiles(callback) {
		const transactionCallback = db => {
			let transaction = db.transaction(Object_Store, 'readonly');

			transaction.onerror = function () {
				console.log('Get All Files Error', transaction.error);
			};

			let files = transaction.objectStore(Object_Store);

			let request = files.getAll();

			request.onerror = function (event) {
				// ConstraintError occurs when an object with the same id already exists
				if (request.error.name === 'ConstraintError') {
					Toast.show('File with such id already exists', TOASTIFY_SEVERITY.WARNING);
					event.preventDefault(); // don't abort the transaction
					event.stopPropagation(); // don't bubble error up, "chew" it
				} else {
					console.log('UNEXPECTED ERROR');
					// unexpected error, can't handle it
					// the transaction will abort
					// we can take care of error in transaction.onabort
				}
			};

			request.onsuccess = function () {
				if (request.result !== undefined) {
					if (isFunction(callback)) callback(request.result);
				}
			};
		};
		this.initDB(transactionCallback);
	}

	updateFile(id, file) {
		const transactionCallback = db => {
			let fileStore = db.transaction(Object_Store, 'readwrite').objectStore(Object_Store);
			const request = fileStore.get(id);

			request.onsuccess = () => {
				// Create a request to update
				const updateRequest = fileStore.put(file);

				updateRequest.onsuccess = () => {
					// console.log(`File updated, file: ${updateRequest.result}`);
				};
			};
		};
		this.initDB(transactionCallback, Object_Store);
	}

	deleteFile(id) {
		const transactionCallback = db => {
			let transaction = db.transaction(Object_Store, 'readwrite');
			let files = transaction.objectStore(Object_Store);

			let deleteRequest = files.delete(id);

			deleteRequest.onsuccess = function () {
				// console.log('File delete successfull');
			};
		};
		this.initDB(transactionCallback, Object_Store);
	}

	deleteFiles(files) {
		const transactionCallback = db => {
			let transaction = db.transaction(Object_Store, 'readwrite');
			let fileStore = transaction.objectStore(Object_Store);

			files.forEach(element => {
				fileStore.delete(element.id);
			});
		};
		this.initDB(transactionCallback, Object_Store);
	}
}

const singletonInstance = new Singleton();

export default singletonInstance;
