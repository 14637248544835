import React from 'react';
import { Button, Table, Icon } from 'semantic-ui-react';
import { DateTime } from 'luxon';

import CheckmarkTableCell from '../../common/CheckmarkTableCell';
import CrossmarkTableCell from '../../common/CrossmarkTableCell';

import { translateString } from '../../../i18n/utils';

export default function FileTableRow({ file = {}, onFilePush, onFileRemove }) {
	return (
		<Table.Row>
			<Table.Cell>{file.content.name}</Table.Cell>
			<Table.Cell>{file.content.type}</Table.Cell>
			<Table.Cell>{file.content.size}</Table.Cell>
			<Table.Cell>{DateTime.fromObject(file.timestamp.c).toLocaleString(DateTime.DATETIME_MED)}</Table.Cell>
			{file.isPushed && <CheckmarkTableCell />}
			{!file.isPushed && <CrossmarkTableCell />}
			<Table.Cell collapsing>
				<Button icon labelPosition="left" onClick={() => onFilePush(file)} disabled={file.disabled}>
					<Icon name="cloud upload" />
					{translateString('upload.button.push')}
				</Button>
			</Table.Cell>
			<Table.Cell collapsing>
				<Button icon labelPosition="left" onClick={() => onFileRemove(file.id)}>
					<Icon name="trash" />
					{translateString('upload.button.remove')}
				</Button>
			</Table.Cell>
		</Table.Row>
	);
}
