import React from 'react';
import { Menu, Image } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
// import i18next from 'i18next';

import MenuItem from './MenuItem';
import { translateComp } from '../../i18n/utils';
// import { changeLanguage } from '../../i18n/utils';

import ibak from '../../images/ibakLogoLight.png';

import '../../css/semanticnavigation.css';

// const countryOptions = [
// 	{ key: 'de', value: 'de', flag: 'de', text: translateComp('languages.de') },
// 	{ key: 'en', value: 'en', flag: 'uk', text: translateComp('languages.en') },
// ];

export default function Navigation() {
	// const [lang, setLanguage] = useState(i18next.language);

	// const updateLanguage = (e, { value }) => {
	// 	changeLanguage(value);
	// 	setLanguage(value);
	// };

	return (
		<Menu fixed="top" stackable size="huge">
			<Menu.Item header as={NavLink} to="/">
				<Image className="ibakImage" src={ibak} alt="IBAK" size="small" />
			</Menu.Item>
			<MenuItem icon="cloud upload" title={translateComp('navigation.upload')} to="/upload" />
			<MenuItem icon="download" title={translateComp('navigation.download')} to="/download" />
			{/*<Menu.Menu position="right">
				<Dropdown search options={countryOptions} as={Menu.Item} onChange={updateLanguage} value={lang} />
    </Menu.Menu>*/}
		</Menu>
	);
}
