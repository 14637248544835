import { post } from '../API';
import { UPLOAD } from '../paths';
import * as Toast from '../../misc/toastHelper';
import { TOASTIFY_SEVERITY } from '../../misc/const';

// eslint-disable-next-line import/prefer-default-export
export function postFile(file) {
	return post(UPLOAD, file, {
		validateStatus: status => {
			return status < 500; // Resolve only if the status code is less than 500
		},
	}).catch(error => {
		if (error.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			Toast.show(error.message, TOASTIFY_SEVERITY.ERROR);
		} else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			console.log(error.request);
		} else {
			// Something happened in setting up the request that triggered an Error
			console.log('Error', error.message);
		}
		console.log(error.config);
	});
}
