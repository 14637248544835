import { get } from '../API';
import { DOWNLOAD, LOG } from '../paths';
import * as Toast from '../../misc/toastHelper';
import { TOASTIFY_SEVERITY } from '../../misc/const';
import { translateString } from '../../i18n/utils';
import * as HTTP from '../httpCodes';

export function getResultFile(id) {
	return get(`${DOWNLOAD}/${id}`)
		.then(response => {
			let message = translateString('download.states.200');
			let ready = true;

			switch (response.status) {
				case 280:
					message = translateString('download.states.280');
					ready = false;
					break;
				case 281:
					message = translateString('download.states.281');
					ready = false;
					break;
				default:
					break;
			}
			return { ready: ready, message: message, data: response.data };
		})
		.catch(error => {
			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				switch (error.response.status) {
					case HTTP.NOT_FOUND:
						throw new Error(translateString('download.states.404'));
					default:
						throw new Error(translateString('download.states.500'));
				}
			} else if (error.request) {
				// The request was made but no response was received
				// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
				// http.ClientRequest in node.js
				console.log(error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.log('Error', error.message);
			}
			console.log(error.config);
		});
}

export function getLog(id) {
	return get(`${LOG}/${id}`).catch(err => {
		switch (err.response.status) {
			case HTTP.NOT_FOUND:
				Toast.show(err.message, TOASTIFY_SEVERITY.ERROR);
				throw new Error(err);
			case HTTP.PRECODITION_FAILED:
				Toast.show(err.message, TOASTIFY_SEVERITY.ERROR);
				throw new Error(err);
			case 480:
				Toast.show(err.message, TOASTIFY_SEVERITY.ERROR);
				throw new Error(err);
			case 481:
				Toast.show(err.message, TOASTIFY_SEVERITY.ERROR);
				throw new Error(err);
			default:
				Toast.show(err.message, TOASTIFY_SEVERITY.ERROR);
				throw new Error(err);
		}
	});
}
