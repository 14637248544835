import { DateTime } from 'luxon';

export const isFileExpired = file => {
	const currentDate = DateTime.now();
	let timestamp = DateTime.fromObject(file.timestamp.c);
	timestamp = timestamp.plus({ days: 2 });
	return timestamp.toMillis() < currentDate.toMillis();
};

export const isFunction = functionToCheck => {
	return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
};

export const isEmpty = value => {
	let isEmpty = true;

	if (value !== null) {
		if (isNumber(value)) {
			isEmpty = false;
		} else if (isString(value) && value !== '') {
			isEmpty = false;
		} else if (isBoolean(value)) {
			isEmpty = false;
		} else if (isArray(value) && value.length > 0) {
			isEmpty = false;
		} else if (isObject(value) && Object.keys(value).length > 0) {
			isEmpty = false;
		}
	}

	return isEmpty;
};

const isObject = o => {
	return Object.prototype.toString.call(o) === '[object Object]';
};

const isArray = x => {
	return Array.isArray(x);
};

const isNumber = n => {
	return typeof n === 'number' && isFinite(n);
};

const isStringType = s => {
	return Object.prototype.toString.call(s) === '[object String]';
};

export const isString = s => {
	if (s === null) {
		return false;
	}

	return isStringType(s);
};

const isBooleanType = x => {
	return typeof x === 'boolean';
};

const isBoolean = arg => {
	if (arg === null) {
		return false;
	} else if (isString(arg)) {
		const boolString = convertToLowerCase(arg).trim();

		return boolString === 'true' || boolString === 'false';
	} else if (isBooleanType(arg)) {
		return true;
	}

	return false;
};

const convertToLowerCase = value => {
	return value.toLowerCase();
};
