import { toast } from 'react-toastify';

import { translateString } from '../i18n/utils';
import { TOASTIFY_SEVERITY } from './const';

/**
 * Erzeugt eine Nachricht, die dem Benutzer angezeigt wird
 * @param  {String} text     Text der Nachricht
 * @param  {String} severity Schwere der Nachricht
 * @param  {Object} position Position der Nachricht
 */
export function show(text, severity, position = toast.POSITION.TOP_RIGHT) {
	const messageText = text.includes('.') ? translateString(text) : text;

	let autoCloseMS = severity === TOASTIFY_SEVERITY.ERROR ? 5000 : 2000;
	if (messageText.length > 0) {
		const autoCloseMSHelper = messageText.length * 70;

		if (autoCloseMSHelper > 2000) {
			autoCloseMS = Number.parseInt((autoCloseMSHelper / 1000).toFixed(), 10) * 1000;
		}
	}

	switch (severity) {
		case TOASTIFY_SEVERITY.ERROR:
			return toast.error(messageText, {
				position: position,
				autoClose: autoCloseMS,
				theme: 'colored',
			});
		case TOASTIFY_SEVERITY.INFO:
			return toast.info(messageText, {
				position: position,
				autoClose: autoCloseMS,
			});
		case TOASTIFY_SEVERITY.SUCCESS:
			return toast.success(messageText, {
				position: position,
				autoClose: autoCloseMS,
				theme: 'colored',
			});
		case TOASTIFY_SEVERITY.WARNING:
			return toast.warn(messageText, {
				position: position,
				autoClose: autoCloseMS,
				theme: 'colored',
			});
		default:
			return toast.info(messageText, {
				position: position,
				autoClose: autoCloseMS,
			});
	}
}

export function promise(promiseCallback, toastId, errorMsg = 'Promise rejected 🤯', successMsg = 'Promise resolved 👌', pendingMsg = 'Promise is pending') {
	return toast.promise(
		promiseCallback,
		{
			pending: pendingMsg,
			success: successMsg,
			error: errorMsg,
		},
		{ theme: 'colored', toastId: toastId },
	);
}

export function dismissAll() {
	return toast.dismiss();
}
