/* eslint-disable max-len */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		fallbackLng: 'en', // use en if detected lng is not available
		debug: true,

		// Namespaces are a feature in i18next internationalization framework which allows you to separate translations that get loaded into multiple files
		ns: ['translations'],
		defaultNS: 'translations',

		// react i18next special options (optional)
		react: {
			bindI18n: 'languageChange loaded', // which events trigger a rerender
			bindI18nStore: '',
			transEmptyNodeValue: '',
			transSupportBasicHtmlNodes: true,
			transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
			useSuspense: true, // assert all provided namespaces are loaded before rendering the component
		},
	});

export default i18n;
