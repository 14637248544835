import { withTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';

import 'semantic-ui-css/semantic.min.css';
import 'react-toastify/dist/ReactToastify.css';
import '../css/App.css';

import RouteContainer from './router/RouterContainer';

function App() {
	return (
		<div>
			<RouteContainer />
			<ToastContainer />
		</div>
	);
}

export default withTranslation()(App);
