// Pfade
export const DEFAULT = '/api/kiki';

// Connection
export const PING = `${DEFAULT}/health/ping`;

// Upload
export const UPLOAD = `${DEFAULT}/upload`;

// Download
export const DOWNLOAD = `${DEFAULT}/download`;
export const LOG = `${DEFAULT}/log`;
