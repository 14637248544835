import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';

import FilesTable from './FilesTable';

import CustomPanel from '../../common/CustomPanel';
import FileUploadPanel from '../../common/FileUploadPanel';

import { isFileExpired, isEmpty } from '../../../misc/utils';
import { translateString } from '../../../i18n/utils';
import { postFile } from '../../../backend/requests/uploadRequests';
import indexedDB from '../../../misc/indexedDB';

export default function UploadPage() {
	const [files, setFiles] = useState([]);

	useEffect(() => {
		indexedDB.getAllFiles(data => setFiles(data));
	}, []);

	useEffect(() => {
		files.forEach(file => {
			if (isFileExpired(file)) {
				indexedDB.deleteFile(file.id);
				setFiles(files.filter(f => f.id !== file.id));
			}
		});
	}, [files]);

	const handleDrop = f => {
		const file = { id: uuidv4(), content: f[0], isPushed: false, disabled: false, timestamp: DateTime.now() };
		setFiles(files.concat([file]));
		indexedDB.addFileTransaction(file);
	};

	const disablePush = () => {
		setFiles(
			files.map(f => {
				return { ...f, disabled: true };
			}),
		);
	};

	const pushFile = file => {
		disablePush();

		postFile(file.content)
			.then(response => {
				updateFile(file.id, { ...file, uploadId: response.data, isPushed: true, disabled: true });
			})
			.catch(err => {
				console.log('Push File Error', err);
				updateFile(file.id, { ...file, disabled: false });
			});
	};

	const updateFile = (id, file) => {
		indexedDB.updateFile(id, file);
		setFiles(
			files.map(f => {
				if (f.id !== id) return f;
				else return file;
			}),
		);
	};

	const removeFile = id => {
		indexedDB.deleteFile(id);
		setFiles(files.filter(f => f.id !== id));
	};

	const removeAllFiles = () => {
		indexedDB.deleteFiles(files);
		setFiles([]);
	};

	const getUploadButton = () => {
		return <FileUploadPanel handleDrop={handleDrop} />;
	};

	return (
		<CustomPanel title={translateString('upload.header')} rightContent={getUploadButton}>
			{!isEmpty(files) && <FilesTable files={files} onFilePush={pushFile} onFileRemove={removeFile} onRemoveAllFiles={removeAllFiles} />}
			{isEmpty(files) && <p>{translateString('upload.info')}</p>}
		</CustomPanel>
	);
}
