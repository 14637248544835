import React, { useState, useEffect } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';

import { getLog } from '../../../backend/requests/downloadRequests';
import { translateString } from '../../../i18n/utils';

export default function LogModal({ item, shouldOpen = false }) {
	const [open, setOpen] = useState(shouldOpen);
	const [isLoading, setLoading] = useState(false);
	const [log, setLog] = useState(null);

	useEffect(() => {
		setOpen(shouldOpen);
	}, [shouldOpen]);

	useEffect(() => {
		if (open) {
			setLoading(true);

			const requestLog = () => {
				getLog(item)
					.then(response => {
						setLoading(false);
						console.log('GET Log Result Response: ', response);
						const logData = response.data.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '<br />');
						setLog(logData);
					})
					.catch(() => {
						setLoading(false);
					});
			};

			requestLog();
		}
	}, [open, item]);

	return (
		<Modal
			open={open}
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			trigger={
				<Button style={{ color: 'white', backgroundColor: '#00183f' }}>
					<Icon name="file text" />
					{translateString('download.button.log')}
				</Button>
			}
		>
			<Modal.Header>{translateString('log.header')}</Modal.Header>
			{!isLoading && (
				<Modal.Content scrolling>
					<Modal.Description>
						{log && <p>{log}</p>}
						{!log && <p>{translateString('log.nodata')}</p>}
					</Modal.Description>
				</Modal.Content>
			)}
			<Modal.Actions>
				<Button style={{ color: 'white', backgroundColor: '#00183f' }} onClick={() => setOpen(false)} primary>
					{translateString('common.close')}
				</Button>
			</Modal.Actions>
		</Modal>
	);
}
