import React from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

const Nav = props => <NavLink {...props} />;

export default function MenuItem({ icon, title, to = '/', onClick }) {
	return (
		<Menu.Item as={Nav} to={to} onClick={onClick}>
			<Icon name={icon} />
			{title}
		</Menu.Item>
	);
}
