import React, { useEffect, useState } from 'react';
import { Button, Table, Icon } from 'semantic-ui-react';
import { DateTime } from 'luxon';

import LogModal from './LogModal';

import { getResultFile } from '../../../backend/requests/downloadRequests';
import { translateString } from '../../../i18n/utils';
import { isEmpty } from '../../../misc/utils';

export default function FileTableRow({ file = {}, onFileDownload, onFileRemove }) {
	const [fileContent, setFileContent] = useState(null);
	const [status, setStatus] = useState('');

	useEffect(() => {
		if (!isEmpty(file)) {
			const requestFileResult = () => {
				getResultFile(file.uploadId)
					.then(({ ready, message, data }) => {
						if (ready && data) {
							setFileContent(data);
						}

						setStatus(message);
					})
					.catch(err => {
						setStatus(err.message);
					});
			};

			requestFileResult();
		}
	}, [file]);

	return (
		<Table.Row>
			<Table.Cell>{file.content.name}</Table.Cell>
			<Table.Cell>{DateTime.fromObject(file.timestamp.c).toLocaleString(DateTime.DATETIME_MED)}</Table.Cell>
			<Table.Cell>{status}</Table.Cell>
			<Table.Cell collapsing>
				<Button disabled={!fileContent} icon labelPosition="left" onClick={() => onFileDownload(file.content.name, fileContent)}>
					<Icon name="cloud upload" />
					{translateString('download.button.download')}
				</Button>
			</Table.Cell>
			<Table.Cell collapsing>
				<LogModal item={file.uploadId} />
			</Table.Cell>
			<Table.Cell collapsing>
				<Button icon labelPosition="left" onClick={() => onFileRemove(file)}>
					<Icon name="trash" />
					{translateString('download.button.remove')}
				</Button>
			</Table.Cell>
		</Table.Row>
	);
}
