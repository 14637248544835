import React from 'react';
import { Table, Button, Icon } from 'semantic-ui-react';

import FileTableRow from './FileTableRow';

import { translateString } from '../../../i18n/utils';

export default function FilesTable({ files = [], onFilePush, onFileRemove, onRemoveAllFiles }) {
	return (
		<Table celled padded>
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell>{translateString('upload.table.name')}</Table.HeaderCell>
					<Table.HeaderCell>{translateString('upload.table.type')}</Table.HeaderCell>
					<Table.HeaderCell>{translateString('upload.table.size')}</Table.HeaderCell>
					<Table.HeaderCell>{translateString('upload.table.uploadedat')}</Table.HeaderCell>
					<Table.HeaderCell collapsing>{translateString('upload.table.send')}</Table.HeaderCell>
					<Table.HeaderCell collapsing />
					<Table.HeaderCell collapsing>
						<Button icon labelPosition="left" onClick={onRemoveAllFiles}>
							<Icon name="trash" />
							{translateString('upload.button.removeAll')}
						</Button>
					</Table.HeaderCell>
				</Table.Row>
			</Table.Header>

			<Table.Body>
				{files.map((file, i) => {
					return <FileTableRow key={'FileTableRow' + i} file={file} onFilePush={onFilePush} onFileRemove={onFileRemove} />;
				})}
			</Table.Body>
		</Table>
	);
}
