import { saveAs } from 'file-saver';

/**
 * Downloads the file
 *
 * @export
 * @param {*} name
 * @param {*} data
 * @param {string} [type='text/csv;charset=utf-8']
 */
export function downloadFile(name, data, type = 'text/csv;charset=utf-8') {
	const blob = new Blob([data], { type: type });
	saveAs(blob, name);
}
