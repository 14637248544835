import React, { useRef } from 'react';
import { Button } from 'semantic-ui-react';

import DragAndDrop from './DragAndDrop';

import { translateString } from '../../i18n/utils';
import { isFunction } from '../../misc/utils';

import '../../css/fileupload.css';

/**
 * Component for uploading files.
 *
 * @component
 * @param  files - optional to show files that are already loaded
 * @param  handleDrop - called when using drag&drop
 * @param  disabled - used to disable upload area
 * @param  accept - used to set which filetype is accepted
 */
export default function FileUploadPanel({ handleDrop, disabled = false, accept }) {
	const inputFile = useRef(null);

	const onButtonClick = () => {
		// `current` points to the mounted file input element
		inputFile.current.click();
	};

	const handleFiles = e => {
		e.preventDefault();
		e.stopPropagation();

		if (e.target.files && e.target.files.length > 0) {
			if (isFunction(handleDrop)) handleDrop(e.target.files);
			e.target.value = null;
		}
	};

	return (
		<DragAndDrop handleDrop={handleDrop} disabled={disabled}>
			<input type="file" id="file" ref={inputFile} onChange={handleFiles} style={{ display: 'none' }} accept={accept} />
			<Button className="addfile" onClick={onButtonClick} disabled={disabled}>
				{translateString('upload.addfile')}
			</Button>
		</DragAndDrop>
	);
}
