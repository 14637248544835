import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

import Navigation from './Navigation';

import DownloadPage from '../pages/download/DownloadPage';
import UploadPage from '../pages/upload/UploadPage';

export default function RouterContainer(props) {
	return (
		<BrowserRouter>
			<Grid>
				<Grid.Row>
					<Navigation />
				</Grid.Row>
				<Grid.Row centered>
					<Routes>
						<Route path="/upload" element={<UploadPage {...props} />} />
						<Route path="/download" element={<DownloadPage {...props} />} />
						<Route path="*" element={<UploadPage />} />
					</Routes>
				</Grid.Row>
			</Grid>
		</BrowserRouter>
	);
}
