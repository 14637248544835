import React, { useState, useEffect } from 'react';

import FilesTable from './FilesTable';
import CustomPanel from '../../common/CustomPanel';

import indexedDB from '../../../misc/indexedDB';
import { isFileExpired, isEmpty } from '../../../misc/utils';
import { downloadFile } from '../../../misc/fileUtils';
import { translateString } from '../../../i18n/utils';

export default function DownloadPage() {
	const [files, setFiles] = useState([]);

	useEffect(() => {
		indexedDB.getAllFiles(data => setFiles(data.filter(f => f.isPushed)));
	}, []);

	useEffect(() => {
		files.forEach(file => {
			if (isFileExpired(file)) {
				indexedDB.deleteFile(file.id);
				setFiles(files.filter(f => f.id !== file.id));
			}
		});
	}, [files]);

	const removeFile = file => {
		indexedDB.deleteFile(file.id);
		setFiles(files.filter(f => f.id !== file.id));
	};

	const removeAllFiles = () => {
		const filesToBeRemoved = files.filter(f => f.isPushed);
		indexedDB.deleteFiles(filesToBeRemoved);
		setFiles([]);
	};

	const handleFileDownload = (name, data) => {
		const reg = /\.([^\\.]+)$/;
		const fileName = name.replace(reg, '').concat('.csv');
		downloadFile(fileName, data);
	};

	return (
		<CustomPanel title={translateString('download.header')}>
			{!isEmpty(files) && <FilesTable files={files} onFileDownload={handleFileDownload} onFileRemove={removeFile} onRemoveAllFiles={removeAllFiles} />}
			{isEmpty(files) && <p>{translateString('download.info')}</p>}
		</CustomPanel>
	);
}
