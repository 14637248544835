import { translateComp } from '../i18n/utils';

export const MONTH = {
	JANUARY: 0,
	FEBRUARY: 1,
	MARCH: 2,
	APRIL: 3,
	MAY: 4,
	JUNE: 5,
	JULY: 6,
	AUGUST: 7,
	SEPTEMBER: 8,
	OCTOBER: 9,
	NOVEMBER: 10,
	DECEMBER: 11,
};

export const TOASTIFY_SEVERITY = {
	INFO: 'info',
	SUCCESS: 'success',
	WARNING: 'warning',
	ERROR: 'error',
};

export const COUNTRIES = [
	{
		key: 'af',
		value: 'af',
		flag: 'af',
		text: translateComp('countries.AF', 'Afghanistan'),
	},
	{
		key: 'ax',
		value: 'ax',
		flag: 'ax',
		text: translateComp('countries.AX', 'Åland'),
	},
	{
		key: 'al',
		value: 'al',
		flag: 'al',
		text: translateComp('countries.AL', 'The Republic of Albania'),
	},
	{
		key: 'dz',
		value: 'dz',
		flag: 'dz',
		text: translateComp('countries.DZ', 'The Peoples Democratic Republic of Algeria'),
	},
	{
		key: 'as',
		value: 'as',
		flag: 'as',
		text: translateComp('countries.AS', 'The Territory of American Samoa'),
	},
	{
		key: 'ad',
		value: 'ad',
		flag: 'ad',
		text: translateComp('countries.AD', 'The Principality of Andorra'),
	},
	{
		key: 'ao',
		value: 'ao',
		flag: 'ao',
		text: translateComp('countries.AO', 'The Republic of Angola'),
	},
	{
		key: 'ai',
		value: 'ai',
		flag: 'ai',
		text: translateComp('countries.AI', 'Anguilla'),
	},
	{
		key: 'ag',
		value: 'ag',
		flag: 'ag',
		text: translateComp('countries.AG', 'Antigua and Barbuda'),
	},
	{
		key: 'ar',
		value: 'ar',
		flag: 'ar',
		text: translateComp('countries.AR', 'The Argentine Republic'),
	},
	{
		key: 'am',
		value: 'am',
		flag: 'am',
		text: translateComp('countries.AM', 'The Republic of Armenia'),
	},
	{
		key: 'aw',
		value: 'aw',
		flag: 'aw',
		text: translateComp('countries.AW', 'Aruba'),
	},
	{
		key: 'au',
		value: 'au',
		flag: 'au',
		text: translateComp('countries.AU', 'The Commonwealth of Australia'),
	},
	{
		key: 'at',
		value: 'at',
		flag: 'at',
		text: translateComp('countries.AT', 'The Republic of Austria'),
	},
	{
		key: 'az',
		value: 'az',
		flag: 'az',
		text: translateComp('countries.AZ', 'The Republic of Azerbaijan'),
	},
	{
		key: 'bs',
		value: 'bs',
		flag: 'bs',
		text: translateComp('countries.BS', 'The Commonwealth of The Bahamas'),
	},
	{
		key: 'bh',
		value: 'bh',
		flag: 'bh',
		text: translateComp('countries.BH', 'The Kingdom of Bahrain'),
	},
	{
		key: 'bd',
		value: 'bd',
		flag: 'bd',
		text: translateComp('countries.BD', 'The Peoples Republic of Bangladesh'),
	},
	{
		key: 'bb',
		value: 'bb',
		flag: 'bb',
		text: translateComp('countries.BB', 'Barbados'),
	},
	{
		key: 'by',
		value: 'by',
		flag: 'by',
		text: translateComp('countries.BY', 'The Republic of Belarus'),
	},
	{
		key: 'be',
		value: 'be',
		flag: 'be',
		text: translateComp('countries.BE', 'The Kingdom of Belgium'),
	},
	{
		key: 'bz',
		value: 'bz',
		flag: 'bz',
		text: translateComp('countries.BZ', 'Belize'),
	},
	{
		key: 'bj',
		value: 'bj',
		flag: 'bj',
		text: translateComp('countries.BJ', 'The Republic of Benin'),
	},
	{
		key: 'bm',
		value: 'bm',
		flag: 'bm',
		text: translateComp('countries.BM', 'Bermuda'),
	},
	{
		key: 'bt',
		value: 'bt',
		flag: 'bt',
		text: translateComp('countries.BT', 'The Kingdom of Bhutan'),
	},
	{
		key: 'bo',
		value: 'bo',
		flag: 'bo',
		text: translateComp('countries.BO', 'The Plurinational State of Bolivia'),
	},
	{
		key: 'ba',
		value: 'ba',
		flag: 'ba',
		text: translateComp('countries.BA', 'Bosnia and Herzegovina'),
	},
	{
		key: 'bw',
		value: 'bw',
		flag: 'bw',
		text: translateComp('countries.BW', 'The Republic of Botswana'),
	},
	{
		key: 'bv',
		value: 'bv',
		flag: 'bv',
		text: translateComp('countries.BV', 'Bouvet Island'),
	},
	{
		key: 'br',
		value: 'br',
		flag: 'br',
		text: translateComp('countries.BR', 'The Federative Republic of Brazil'),
	},
	{
		key: 'io',
		value: 'io',
		flag: 'io',
		text: translateComp('countries.IO', 'The British Indian Ocean Territory'),
	},
	{
		key: 'bn',
		value: 'bn',
		flag: 'bn',
		text: translateComp('countries.BN', 'The Nation of Brunei, the Abode of Peace'),
	},
	{
		key: 'bg',
		value: 'bg',
		flag: 'bg',
		text: translateComp('countries.BG', 'The Republic of Bulgaria'),
	},
	{
		key: 'bf',
		value: 'bf',
		flag: 'bf',
		text: translateComp('countries.BF', 'Burkina Faso'),
	},
	{
		key: 'bi',
		value: 'bi',
		flag: 'bi',
		text: translateComp('countries.BI', 'The Republic of Burundi'),
	},
	{
		key: 'cv',
		value: 'cv',
		flag: 'cv',
		text: translateComp('countries.CV', 'The Republic of Cabo Verde'),
	},
	{
		key: 'kh',
		value: 'kh',
		flag: 'kh',
		text: translateComp('countries.KH', 'The Kingdom of Cambodia'),
	},
	{
		key: 'cm',
		value: 'cm',
		flag: 'cm',
		text: translateComp('countries.CM', 'The Republic of Cameroon'),
	},
	{
		key: 'ca',
		value: 'ca',
		flag: 'ca',
		text: translateComp('countries.CA', 'Canada'),
	},
	{
		key: 'ky',
		value: 'ky',
		flag: 'ky',
		text: translateComp('countries.KY', 'The Cayman Islands'),
	},
	{
		key: 'cf',
		value: 'cf',
		flag: 'cf',
		text: translateComp('countries.CF', 'The Central African Republic'),
	},
	{
		key: 'td',
		value: 'td',
		flag: 'td',
		text: translateComp('countries.TD', 'The Republic of Chad'),
	},
	{
		key: 'cl',
		value: 'cl',
		flag: 'cl',
		text: translateComp('countries.CL', 'The Republic of Chile'),
	},
	{
		key: 'cn',
		value: 'cn',
		flag: 'cn',
		text: translateComp('countries.CN', 'The Peoples Republic of China'),
	},
	{
		key: 'cx',
		value: 'cx',
		flag: 'cx',
		text: translateComp('countries.CX', 'The Territory of Christmas Island'),
	},
	{
		key: 'cc',
		value: 'cc',
		flag: 'cc',
		text: translateComp('countries.CC', 'The Territory of Cocos (Keeling) Islands'),
	},
	{
		key: 'co',
		value: 'co',
		flag: 'co',
		text: translateComp('countries.CO', 'The Republic of Colombia'),
	},
	{
		key: 'km',
		value: 'km',
		flag: 'km',
		text: translateComp('countries.KM', 'The Union of the Comoros'),
	},
	{
		key: 'cd',
		value: 'cd',
		flag: 'cd',
		text: translateComp('countries.CD', 'The Democratic Republic of the Congo'),
	},
	{
		key: 'cg',
		value: 'cg',
		flag: 'cg',
		text: translateComp('countries.CG', 'The Republic of the Congo'),
	},
	{
		key: 'ck',
		value: 'ck',
		flag: 'ck',
		text: translateComp('countries.CK', 'The Cook Islands'),
	},
	{
		key: 'cr',
		value: 'cr',
		flag: 'cr',
		text: translateComp('countries.CR', 'The Republic of Costa Rica'),
	},
	{
		key: 'ci',
		value: 'ci',
		flag: 'ci',
		text: translateComp('countries.CI', 'The Republic of Côte dIvoire'),
	},
	{
		key: 'hr',
		value: 'hr',
		flag: 'hr',
		text: translateComp('countries.HR', 'The Republic of Croatia'),
	},
	{
		key: 'cu',
		value: 'cu',
		flag: 'cu',
		text: translateComp('countries.CU', 'The Republic of Cuba'),
	},
	{
		key: 'cy',
		value: 'cy',
		flag: 'cy',
		text: translateComp('countries.CY', 'The Republic of Cyprus'),
	},
	{
		key: 'cz',
		value: 'cz',
		flag: 'cz',
		text: translateComp('countries.CZ', 'The Czech Republic'),
	},
	{
		key: 'dk',
		value: 'dk',
		flag: 'dk',
		text: translateComp('countries.DK', 'The Kingdom of Denmark'),
	},
	{
		key: 'dj',
		value: 'dj',
		flag: 'dj',
		text: translateComp('countries.DJ', 'The Republic of Djibouti'),
	},
	{
		key: 'dm',
		value: 'dm',
		flag: 'dm',
		text: translateComp('countries.DM', 'The Commonwealth of Dominica'),
	},
	{
		key: 'do',
		value: 'do',
		flag: 'do',
		text: translateComp('countries.DO', 'The Dominican Republic'),
	},
	{
		key: 'ec',
		value: 'ec',
		flag: 'ec',
		text: translateComp('countries.EC', 'The Republic of Ecuador'),
	},
	{
		key: 'eg',
		value: 'eg',
		flag: 'eg',
		text: translateComp('countries.EG', 'The Arab Republic of Egypt'),
	},
	{
		key: 'sv',
		value: 'sv',
		flag: 'sv',
		text: translateComp('countries.SV', 'The Republic of El Salvador'),
	},
	{
		key: 'gq',
		value: 'gq',
		flag: 'gq',
		text: translateComp('countries.GQ', 'The Republic of Equatorial Guinea'),
	},
	{
		key: 'er',
		value: 'er',
		flag: 'er',
		text: translateComp('countries.ER', 'The State of Eritrea'),
	},
	{
		key: 'ee',
		value: 'ee',
		flag: 'ee',
		text: translateComp('countries.EE', 'The Republic of Estonia'),
	},
	{
		key: 'sz',
		value: 'sz',
		flag: 'sz',
		text: translateComp('countries.SZ', 'The Kingdom of Eswatini'),
	},
	{
		key: 'et',
		value: 'et',
		flag: 'et',
		text: translateComp('countries.ET', 'The Federal Democratic Republic of Ethiopia'),
	},
	{
		key: 'fk',
		value: 'fk',
		flag: 'fk',
		text: translateComp('countries.FK', 'The Falkland Islands'),
	},
	{
		key: 'fo',
		value: 'fo',
		flag: 'fo',
		text: translateComp('countries.FO', 'The Faroe Islands'),
	},
	{
		key: 'fj',
		value: 'fj',
		flag: 'fj',
		text: translateComp('countries.FJ', 'The Republic of Fiji'),
	},
	{
		key: 'fi',
		value: 'fi',
		flag: 'fi',
		text: translateComp('countries.FI', 'The Republic of Finland'),
	},
	{
		key: 'fr',
		value: 'fr',
		flag: 'fr',
		text: translateComp('countries.FR', 'The French Republic'),
	},
	{
		key: 'gf',
		value: 'gf',
		flag: 'gf',
		text: translateComp('countries.GF', 'Guyane'),
	},
	{
		key: 'pf',
		value: 'pf',
		flag: 'pf',
		text: translateComp('countries.PF', 'French Polynesia'),
	},
	{
		key: 'tf',
		value: 'tf',
		flag: 'tf',
		text: translateComp('countries.TF', 'The French Southern and Antarctic Lands'),
	},
	{
		key: 'ga',
		value: 'ga',
		flag: 'ga',
		text: translateComp('countries.GA', 'The Gabonese Republic'),
	},
	{
		key: 'gm',
		value: 'gm',
		flag: 'gm',
		text: translateComp('countries.GM', 'The Republic of The Gambia'),
	},
	{
		key: 'ge',
		value: 'ge',
		flag: 'ge',
		text: translateComp('countries.GE', 'Georgia'),
	},
	{
		key: 'de',
		value: 'de',
		flag: 'de',
		text: translateComp('countries.DE', 'The Federal Republic of Germany'),
	},
	{
		key: 'gh',
		value: 'gh',
		flag: 'gh',
		text: translateComp('countries.GH', 'The Republic of Ghana'),
	},
	{
		key: 'gi',
		value: 'gi',
		flag: 'gi',
		text: translateComp('countries.GI', 'Gibraltar'),
	},
	{
		key: 'gr',
		value: 'gr',
		flag: 'gr',
		text: translateComp('countries.GR', 'The Hellenic Republic'),
	},
	{
		key: 'gl',
		value: 'gl',
		flag: 'gl',
		text: translateComp('countries.GL', 'Kalaallit Nunaat'),
	},
	{
		key: 'gd',
		value: 'gd',
		flag: 'gd',
		text: translateComp('countries.GD', 'Grenada'),
	},
	{
		key: 'gp',
		value: 'gp',
		flag: 'gp',
		text: translateComp('countries.GP', 'Guadeloupe'),
	},
	{
		key: 'gu',
		value: 'gu',
		flag: 'gu',
		text: translateComp('countries.GU', 'The Territory of Guam'),
	},
	{
		key: 'gt',
		value: 'gt',
		flag: 'gt',
		text: translateComp('countries.GT', 'The Republic of Guatemala'),
	},
	{
		key: 'gn',
		value: 'gn',
		flag: 'gn',
		text: translateComp('countries.GN', 'The Republic of Guinea'),
	},
	{
		key: 'gw',
		value: 'gw',
		flag: 'gw',
		text: translateComp('countries.GW', 'The Republic of Guinea-Bissau'),
	},
	{
		key: 'gy',
		value: 'gy',
		flag: 'gy',
		text: translateComp('countries.GY', 'The Co-operative Republic of Guyana'),
	},
	{
		key: 'ht',
		value: 'ht',
		flag: 'ht',
		text: translateComp('countries.HT', 'The Republic of Haiti'),
	},
	{
		key: 'hm',
		value: 'hm',
		flag: 'hm',
		text: translateComp('countries.HM', 'The Territory of Heard Island and McDonald Islands'),
	},
	{
		key: 'va',
		value: 'va',
		flag: 'va',
		text: translateComp('countries.VA', 'The Holy See'),
	},
	{
		key: 'hn',
		value: 'hn',
		flag: 'hn',
		text: translateComp('countries.HN', 'The Republic of Honduras'),
	},
	{
		key: 'hk',
		value: 'hk',
		flag: 'hk',
		text: translateComp('countries.HK', 'The Hong Kong Special Administrative Region of China'),
	},
	{
		key: 'hu',
		value: 'hu',
		flag: 'hu',
		text: translateComp('countries.HU', 'Hungary'),
	},
	{
		key: 'is',
		value: 'is',
		flag: 'is',
		text: translateComp('countries.IS', 'Iceland'),
	},
	{
		key: 'in',
		value: 'in',
		flag: 'in',
		text: translateComp('countries.IN', 'The Republic of India'),
	},
	{
		key: 'id',
		value: 'id',
		flag: 'id',
		text: translateComp('countries.ID', 'The Republic of Indonesia'),
	},
	{
		key: 'ir',
		value: 'ir',
		flag: 'ir',
		text: translateComp('countries.IR', 'The Islamic Republic of Iran'),
	},
	{
		key: 'iq',
		value: 'iq',
		flag: 'iq',
		text: translateComp('countries.IQ', 'The Republic of Iraq'),
	},
	{
		key: 'ie',
		value: 'ie',
		flag: 'ie',
		text: translateComp('countries.IE', 'Ireland'),
	},
	{
		key: 'il',
		value: 'il',
		flag: 'il',
		text: translateComp('countries.IL', 'The State of Israel'),
	},
	{
		key: 'it',
		value: 'it',
		flag: 'it',
		text: translateComp('countries.IT', 'The Italian Republic'),
	},
	{
		key: 'jm',
		value: 'jm',
		flag: 'jm',
		text: translateComp('countries.JM', 'Jamaica'),
	},
	{
		key: 'jp',
		value: 'jp',
		flag: 'jp',
		text: translateComp('countries.JP', 'Japan'),
	},
	{
		key: 'jo',
		value: 'jo',
		flag: 'jo',
		text: translateComp('countries.JO', 'The Hashemite Kingdom of Jordan'),
	},
	{
		key: 'kz',
		value: 'kz',
		flag: 'kz',
		text: translateComp('countries.KZ', 'The Republic of Kazakhstan'),
	},
	{
		key: 'ke',
		value: 'ke',
		flag: 'ke',
		text: translateComp('countries.KE', 'The Republic of Kenya'),
	},
	{
		key: 'ki',
		value: 'ki',
		flag: 'ki',
		text: translateComp('countries.KI', 'The Republic of Kiribati'),
	},
	{
		key: 'kp',
		value: 'kp',
		flag: 'kp',
		text: translateComp('countries.KP', 'The Democratic Peoples Republic of Korea'),
	},
	{
		key: 'kr',
		value: 'kr',
		flag: 'kr',
		text: translateComp('countries.KR', 'The Republic of Korea'),
	},
	{
		key: 'kw',
		value: 'kw',
		flag: 'kw',
		text: translateComp('countries.KW', 'The State of Kuwait'),
	},
	{
		key: 'kg',
		value: 'kg',
		flag: 'kg',
		text: translateComp('countries.KG', 'The Kyrgyz Republic'),
	},
	{
		key: 'la',
		value: 'la',
		flag: 'la',
		text: translateComp('countries.LA', 'The Lao Peoples Democratic Republic'),
	},
	{
		key: 'lv',
		value: 'lv',
		flag: 'lv',
		text: translateComp('countries.LV', 'The Republic of Latvia'),
	},
	{
		key: 'lb',
		value: 'lb',
		flag: 'lb',
		text: translateComp('countries.LB', 'The Lebanese Republic'),
	},
	{
		key: 'ls',
		value: 'ls',
		flag: 'ls',
		text: translateComp('countries.LS', 'The Kingdom of Lesotho'),
	},
	{
		key: 'lr',
		value: 'lr',
		flag: 'lr',
		text: translateComp('countries.LR', 'The Republic of Liberia'),
	},
	{
		key: 'ly',
		value: 'ly',
		flag: 'ly',
		text: translateComp('countries.LY', 'The State of Libya'),
	},
	{
		key: 'li',
		value: 'li',
		flag: 'li',
		text: translateComp('countries.LI', 'The Principality of Liechtenstein'),
	},
	{
		key: 'lt',
		value: 'lt',
		flag: 'lt',
		text: translateComp('countries.LT', 'The Republic of Lithuania'),
	},
	{
		key: 'lu',
		value: 'lu',
		flag: 'lu',
		text: translateComp('countries.LU', 'The Grand Duchy of Luxembourg'),
	},
	{
		key: 'mo',
		value: 'mo',
		flag: 'mo',
		text: translateComp('countries.MO', 'Macao Special Administrative Region of China'),
	},
	{
		key: 'mk',
		value: 'mk',
		flag: 'mk',
		text: translateComp('countries.MK', 'Republic of North Macedonia'),
	},
	{
		key: 'mg',
		value: 'mg',
		flag: 'mg',
		text: translateComp('countries.MG', 'The Republic of Madagascar'),
	},
	{
		key: 'mw',
		value: 'mw',
		flag: 'mw',
		text: translateComp('countries.MW', 'The Republic of Malawi'),
	},
	{
		key: 'my',
		value: 'my',
		flag: 'my',
		text: translateComp('countries.MY', 'Malaysia'),
	},
	{
		key: 'mv',
		value: 'mv',
		flag: 'mv',
		text: translateComp('countries.MV', 'The Republic of Maldives'),
	},
	{
		key: 'ml',
		value: 'ml',
		flag: 'ml',
		text: translateComp('countries.ML', 'The Republic of Mali'),
	},
	{
		key: 'mt',
		value: 'mt',
		flag: 'mt',
		text: translateComp('countries.MT', 'The Republic of Malta'),
	},
	{
		key: 'mh',
		value: 'mh',
		flag: 'mh',
		text: translateComp('countries.MH', 'The Republic of the Marshall Islands'),
	},
	{
		key: 'mq',
		value: 'mq',
		flag: 'mq',
		text: translateComp('countries.MQ', 'Martinique'),
	},
	{
		key: 'mr',
		value: 'mr',
		flag: 'mr',
		text: translateComp('countries.MR', 'The Islamic Republic of Mauritania'),
	},
	{
		key: 'mu',
		value: 'mu',
		flag: 'mu',
		text: translateComp('countries.MU', 'The Republic of Mauritius'),
	},
	{
		key: 'yt',
		value: 'yt',
		flag: 'yt',
		text: translateComp('countries.YT', 'The Department of Mayotte'),
	},
	{
		key: 'mx',
		value: 'mx',
		flag: 'mx',
		text: translateComp('countries.MX', 'The United Mexican States'),
	},
	{
		key: 'fm',
		value: 'fm',
		flag: 'fm',
		text: translateComp('countries.FM', 'The Federated States of Micronesia'),
	},
	{
		key: 'md',
		value: 'md',
		flag: 'md',
		text: translateComp('countries.MD', 'The Republic of Moldova'),
	},
	{
		key: 'mc',
		value: 'mc',
		flag: 'mc',
		text: translateComp('countries.MC', 'The Principality of Monaco'),
	},
	{
		key: 'mn',
		value: 'mn',
		flag: 'mn',
		text: translateComp('countries.MN', 'The State of Mongolia'),
	},
	{
		key: 'me',
		value: 'me',
		flag: 'me',
		text: translateComp('countries.ME', 'Montenegro'),
	},
	{
		key: 'ms',
		value: 'ms',
		flag: 'ms',
		text: translateComp('countries.MS', 'Montserrat'),
	},
	{
		key: 'ma',
		value: 'ma',
		flag: 'ma',
		text: translateComp('countries.MA', 'The Kingdom of Morocco'),
	},
	{
		key: 'mz',
		value: 'mz',
		flag: 'mz',
		text: translateComp('countries.MZ', 'The Republic of Mozambique'),
	},
	{
		key: 'mm',
		value: 'mm',
		flag: 'mm',
		text: translateComp('countries.MM', 'The Republic of the Union of Myanmar'),
	},
	{
		key: 'na',
		value: 'na',
		flag: 'na',
		text: translateComp('countries.NA', 'The Republic of Namibia'),
	},
	{
		key: 'nr',
		value: 'nr',
		flag: 'nr',
		text: translateComp('countries.NR', 'The Republic of Nauru'),
	},
	{
		key: 'np',
		value: 'np',
		flag: 'np',
		text: translateComp('countries.NP', 'The Federal Democratic Republic of Nepal'),
	},
	{
		key: 'nl',
		value: 'nl',
		flag: 'nl',
		text: translateComp('countries.NL', 'The Kingdom of the Netherlands'),
	},
	{
		key: 'nc',
		value: 'nc',
		flag: 'nc',
		text: translateComp('countries.NC', 'New Caledonia'),
	},
	{
		key: 'nz',
		value: 'nz',
		flag: 'nz',
		text: translateComp('countries.NZ', 'New Zealand'),
	},
	{
		key: 'ni',
		value: 'ni',
		flag: 'ni',
		text: translateComp('countries.NI', 'The Republic of Nicaragua'),
	},
	{
		key: 'ne',
		value: 'ne',
		flag: 'ne',
		text: translateComp('countries.NE', 'The Republic of the Niger'),
	},
	{
		key: 'ng',
		value: 'ng',
		flag: 'ng',
		text: translateComp('countries.NG', 'The Federal Republic of Nigeria'),
	},
	{
		key: 'nu',
		value: 'nu',
		flag: 'nu',
		text: translateComp('countries.NU', 'Niue'),
	},
	{
		key: 'nf',
		value: 'nf',
		flag: 'nf',
		text: translateComp('countries.NF', 'The Territory of Norfolk Island'),
	},
	{
		key: 'mp',
		value: 'mp',
		flag: 'mp',
		text: translateComp('countries.MP', 'The Commonwealth of the Northern Mariana Islands'),
	},
	{
		key: 'no',
		value: 'no',
		flag: 'no',
		text: translateComp('countries.NO', 'The Kingdom of Norway'),
	},
	{
		key: 'om',
		value: 'om',
		flag: 'om',
		text: translateComp('countries.OM', 'The Sultanate of Oman'),
	},
	{
		key: 'pk',
		value: 'pk',
		flag: 'pk',
		text: translateComp('countries.PK', 'The Islamic Republic of Pakistan'),
	},
	{
		key: 'pw',
		value: 'pw',
		flag: 'pw',
		text: translateComp('countries.PW', 'The Republic of Palau'),
	},
	{
		key: 'ps',
		value: 'ps',
		flag: 'ps',
		text: translateComp('countries.PS', 'The State of Palestine'),
	},
	{
		key: 'pa',
		value: 'pa',
		flag: 'pa',
		text: translateComp('countries.PA', 'The Republic of Panamá'),
	},
	{
		key: 'pg',
		value: 'pg',
		flag: 'pg',
		text: translateComp('countries.PG', 'The Independent State of Papua New Guinea'),
	},
	{
		key: 'py',
		value: 'py',
		flag: 'py',
		text: translateComp('countries.PY', 'The Republic of Paraguay'),
	},
	{
		key: 'pe',
		value: 'pe',
		flag: 'pe',
		text: translateComp('countries.PE', 'The Republic of Perú'),
	},
	{
		key: 'ph',
		value: 'ph',
		flag: 'ph',
		text: translateComp('countries.PH', 'The Republic of the Philippines'),
	},
	{
		key: 'pn',
		value: 'pn',
		flag: 'pn',
		text: translateComp('countries.PN', 'The Pitcairn, Henderson, Ducie and Oeno Islands'),
	},
	{
		key: 'pl',
		value: 'pl',
		flag: 'pl',
		text: translateComp('countries.PL', 'The Republic of Poland'),
	},
	{
		key: 'pt',
		value: 'pt',
		flag: 'pt',
		text: translateComp('countries.PT', 'The Portuguese Republic'),
	},
	{
		key: 'pr',
		value: 'pr',
		flag: 'pr',
		text: translateComp('countries.PR', 'The Commonwealth of Puerto Rico'),
	},
	{
		key: 'qa',
		value: 'qa',
		flag: 'qa',
		text: translateComp('countries.QA', 'The State of Qatar'),
	},
	{
		key: 're',
		value: 're',
		flag: 're',
		text: translateComp('countries.RE', 'Réunion'),
	},
	{
		key: 'ro',
		value: 'ro',
		flag: 'ro',
		text: translateComp('countries.RO', 'Romania'),
	},
	{
		key: 'ru',
		value: 'ru',
		flag: 'ru',
		text: translateComp('countries.RU', 'The Russian Federation'),
	},
	{
		key: 'rw',
		value: 'rw',
		flag: 'rw',
		text: translateComp('countries.RW', 'The Republic of Rwanda'),
	},
	{
		key: 'sh',
		value: 'sh',
		flag: 'sh',
		text: translateComp('countries.SH', 'Saint Helena, Ascension and Tristan da Cunha'),
	},
	{
		key: 'kn',
		value: 'kn',
		flag: 'kn',
		text: translateComp('countries.KN', 'Saint Kitts and Nevis'),
	},
	{
		key: 'lc',
		value: 'lc',
		flag: 'lc',
		text: translateComp('countries.LC', 'Saint Lucia'),
	},
	{
		key: 'pm',
		value: 'pm',
		flag: 'pm',
		text: translateComp('countries.PM', 'The Overseas Collectivity of Saint-Pierre and Miquelon'),
	},
	{
		key: 'vc',
		value: 'vc',
		flag: 'vc',
		text: translateComp('countries.VC', 'Saint Vincent and the Grenadines'),
	},
	{
		key: 'ws',
		value: 'ws',
		flag: 'ws',
		text: translateComp('countries.WS', 'The Independent State of Samoa'),
	},
	{
		key: 'sm',
		value: 'sm',
		flag: 'sm',
		text: translateComp('countries.SM', 'The Republic of San Marino'),
	},
	{
		key: 'st',
		value: 'st',
		flag: 'st',
		text: translateComp('countries.ST', 'The Democratic Republic of São Tomé and Príncipe'),
	},
	{
		key: 'sa',
		value: 'sa',
		flag: 'sa',
		text: translateComp('countries.SA', 'The Kingdom of Saudi Arabia'),
	},
	{
		key: 'sn',
		value: 'sn',
		flag: 'sn',
		text: translateComp('countries.SN', 'The Republic of Senegal'),
	},
	{
		key: 'rs',
		value: 'rs',
		flag: 'rs',
		text: translateComp('countries.RS', 'The Republic of Serbia'),
	},
	{
		key: 'sc',
		value: 'sc',
		flag: 'sc',
		text: translateComp('countries.SC', 'The Republic of Seychelles'),
	},
	{
		key: 'sl',
		value: 'sl',
		flag: 'sl',
		text: translateComp('countries.SL', 'The Republic of Sierra Leone'),
	},
	{
		key: 'sg',
		value: 'sg',
		flag: 'sg',
		text: translateComp('countries.SG', 'The Republic of Singapore'),
	},
	{
		key: 'sk',
		value: 'sk',
		flag: 'sk',
		text: translateComp('countries.SK', 'The Slovak Republic'),
	},
	{
		key: 'si',
		value: 'si',
		flag: 'si',
		text: translateComp('countries.SI', 'The Republic of Slovenia'),
	},
	{
		key: 'sb',
		value: 'sb',
		flag: 'sb',
		text: translateComp('countries.SB', 'The Solomon Islands'),
	},
	{
		key: 'so',
		value: 'so',
		flag: 'so',
		text: translateComp('countries.SO', 'The Federal Republic of Somalia'),
	},
	{
		key: 'za',
		value: 'za',
		flag: 'za',
		text: translateComp('countries.ZA', 'The Republic of South Africa'),
	},
	{
		key: 'gs',
		value: 'gs',
		flag: 'gs',
		text: translateComp('countries.GS', 'South Georgia and the South Sandwich Islands'),
	},
	{
		key: 'es',
		value: 'es',
		flag: 'es',
		text: translateComp('countries.ES', 'The Kingdom of Spain'),
	},
	{
		key: 'lk',
		value: 'lk',
		flag: 'lk',
		text: translateComp('countries.LK', 'The Democratic Socialist Republic of Sri Lanka'),
	},
	{
		key: 'sd',
		value: 'sd',
		flag: 'sd',
		text: translateComp('countries.SD', 'The Republic of the Sudan'),
	},
	{
		key: 'sr',
		value: 'sr',
		flag: 'sr',
		text: translateComp('countries.SR', 'The Republic of Suriname'),
	},
	{
		key: 'sj',
		value: 'sj',
		flag: 'sj',
		text: translateComp('countries.SJ', 'Svalbard and Jan Mayen'),
	},
	{
		key: 'se',
		value: 'se',
		flag: 'se',
		text: translateComp('countries.SE', 'The Kingdom of Sweden'),
	},
	{
		key: 'ch',
		value: 'ch',
		flag: 'ch',
		text: translateComp('countries.CH', 'The Swiss Confederation'),
	},
	{
		key: 'sy',
		value: 'sy',
		flag: 'sy',
		text: translateComp('countries.SY', 'The Syrian Arab Republic'),
	},
	{
		key: 'tw',
		value: 'tw',
		flag: 'tw',
		text: translateComp('countries.TW', 'The Republic of China'),
	},
	{
		key: 'tj',
		value: 'tj',
		flag: 'tj',
		text: translateComp('countries.TJ', 'The Republic of Tajikistan'),
	},
	{
		key: 'tz',
		value: 'tz',
		flag: 'tz',
		text: translateComp('countries.TZ', 'The United Republic of Tanzania'),
	},
	{
		key: 'th',
		value: 'th',
		flag: 'th',
		text: translateComp('countries.TH', 'The Kingdom of Thailand'),
	},
	{
		key: 'tl',
		value: 'tl',
		flag: 'tl',
		text: translateComp('countries.TL', 'The Democratic Republic of Timor-Leste'),
	},
	{
		key: 'tg',
		value: 'tg',
		flag: 'tg',
		text: translateComp('countries.TG', 'The Togolese Republic'),
	},
	{
		key: 'tk',
		value: 'tk',
		flag: 'tk',
		text: translateComp('countries.TK', 'Tokelau'),
	},
	{
		key: 'to',
		value: 'to',
		flag: 'to',
		text: translateComp('countries.TO', 'The Kingdom of Tonga'),
	},
	{
		key: 'tt',
		value: 'tt',
		flag: 'tt',
		text: translateComp('countries.TT', 'The Republic of Trinidad and Tobago'),
	},
	{
		key: 'tn',
		value: 'tn',
		flag: 'tn',
		text: translateComp('countries.TN', 'The Republic of Tunisia'),
	},
	{
		key: 'tr',
		value: 'tr',
		flag: 'tr',
		text: translateComp('countries.TR', 'The Republic of Turkey'),
	},
	{
		key: 'tc',
		value: 'tc',
		flag: 'tc',
		text: translateComp('countries.TC', 'The Turks and Caicos Islands'),
	},
	{
		key: 'tv',
		value: 'tv',
		flag: 'tv',
		text: translateComp('countries.TV', 'Tuvalu'),
	},
	{
		key: 'ug',
		value: 'ug',
		flag: 'ug',
		text: translateComp('countries.UG', 'The Republic of Uganda'),
	},
	{
		key: 'ua',
		value: 'ua',
		flag: 'ua',
		text: translateComp('countries.UA', 'Ukraine'),
	},
	{
		key: 'ae',
		value: 'ae',
		flag: 'ae',
		text: translateComp('countries.AE', 'The United Arab Emirates'),
	},
	{
		key: 'gb',
		value: 'gb',
		flag: 'gb',
		text: translateComp('countries.GB', 'The United Kingdom of Great Britain and Northern Ireland'),
	},
	{
		key: 'um',
		value: 'um',
		flag: 'um',
		text: translateComp('countries.UM', 'Baker Island, Howland Island, Jarvis Island, Johnston Atoll, Kingman Reef, Midway Atoll, Navassa Island, Palmyra Atoll, and Wake Island'),
	},
	{
		key: 'us',
		value: 'us',
		flag: 'us',
		text: translateComp('countries.US', 'The United States of America'),
	},
	{
		key: 'uy',
		value: 'uy',
		flag: 'uy',
		text: translateComp('countries.UY', 'The Oriental Republic of Uruguay'),
	},
	{
		key: 'uz',
		value: 'uz',
		flag: 'uz',
		text: translateComp('countries.UZ', 'The Republic of Uzbekistan'),
	},
	{
		key: 'vu',
		value: 'vu',
		flag: 'vu',
		text: translateComp('countries.VU', 'The Republic of Vanuatu'),
	},
	{
		key: 've',
		value: 've',
		flag: 've',
		text: translateComp('countries.VE', 'The Bolivarian Republic of Venezuela'),
	},
	{
		key: 'vn',
		value: 'vn',
		flag: 'vn',
		text: translateComp('countries.VN', 'The Socialist Republic of Viet Nam'),
	},
	{
		key: 'vg',
		value: 'vg',
		flag: 'vg',
		text: translateComp('countries.VG', 'The Virgin Islands'),
	},
	{
		key: 'vi',
		value: 'vi',
		flag: 'vi',
		text: translateComp('countries.VI', 'The Virgin Islands of the United States'),
	},
	{
		key: 'wf',
		value: 'wf',
		flag: 'wf',
		text: translateComp('countries.WF', 'The Territory of the Wallis and Futuna Islands'),
	},
	{
		key: 'eh',
		value: 'eh',
		flag: 'eh',
		text: translateComp('countries.EH', 'The Sahrawi Arab Democratic Republic'),
	},
	{
		key: 'ye',
		value: 'ye',
		flag: 'ye',
		text: translateComp('countries.YE', 'The Republic of Yemen'),
	},
	{
		key: 'zm',
		value: 'zm',
		flag: 'zm',
		text: translateComp('countries.ZM', 'The Republic of Zambia'),
	},
	{
		key: 'zw',
		value: 'zw',
		flag: 'zw',
		text: translateComp('countries.ZW', 'The Republic of Zimbabwe'),
	},
];
