import React from 'react';
import { Table, Icon } from 'semantic-ui-react';

/**
 * Component for a table cell
 *
 * @component
 * @param  positive - used to set if table cell should be positive
 * @param  negative - used to set if table cell should be negative
 * @param  warning - used to set if table cell should be warning
 * @param  disabled - used to set if table cell is disabled
 * @param  icon - used to set table cell icon
 * @param  text - used to set table cell text
 */
export default function TableCell({ positive, negative, warning, disabled, icon, text, collapsing }) {
	return (
		<Table.Cell warning={warning} positive={positive} negative={negative} disabled={disabled} collapsing={collapsing} textAlign="center">
			{icon && <Icon name={icon} />}
			{text}
		</Table.Cell>
	);
}
