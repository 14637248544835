import React, { Component } from 'react';

import { translateString } from '../../i18n/utils';

/**
 * Component for adding drag & drop functionality.
 *
 * @component
 * @param  disabled - used to set disabled
 * @param  handleDrop - called when file gets dropped in area
 * @param  children - used to set over which component drag & drop should be set
 */
export default class DragAndDrop extends Component {
	dropRef = React.createRef();

	constructor(props) {
		super(props);

		this.state = {
			isDragging: false,
		};
	}

	componentDidMount() {
		const div = this.dropRef.current;
		div.addEventListener('dragenter', this.handleDragIn);
		div.addEventListener('dragleave', this.handleDragOut);
		div.addEventListener('dragover', this.handleDrag);
		div.addEventListener('drop', this.handleDrop);
	}

	componentWillUnmount() {
		const div = this.dropRef.current;
		div.removeEventListener('dragenter', this.handleDragIn);
		div.removeEventListener('dragleave', this.handleDragOut);
		div.removeEventListener('dragover', this.handleDrag);
		div.removeEventListener('drop', this.handleDrop);
	}

	handleDrag = e => {
		e.preventDefault();
		e.stopPropagation();
	};

	handleDragIn = e => {
		const { disabled } = this.props;

		e.preventDefault();
		e.stopPropagation();

		if (!disabled) {
			this.dragCounter++;
			if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
				this.setState({ isDragging: true });
			}
		}
	};

	handleDragOut = e => {
		const { disabled } = this.props;

		e.preventDefault();
		e.stopPropagation();

		if (!disabled) {
			this.dragCounter--;
			if (this.dragCounter === 0) {
				this.setState({ isDragging: false });
			}
		}
	};

	handleDrop = e => {
		const { handleDrop, disabled } = this.props;

		e.preventDefault();
		e.stopPropagation();

		if (!disabled) {
			this.setState({ isDragging: false });

			if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
				handleDrop(e.dataTransfer.files);
				// e.dataTransfer.clearData();
				this.dragCounter = 0;
			}
		}
	};

	render() {
		const { isDragging } = this.state;
		const { children } = this.props;

		return (
			<div style={{ display: 'inline-block', position: 'relative' }} ref={this.dropRef}>
				{isDragging && (
					<div
						style={{
							border: 'dashed grey 4px',
							backgroundColor: 'rgba(255,255,255,.8)',
							position: 'absolute',
							top: 0,
							bottom: 0,
							left: 0,
							right: 0,
							zIndex: 9999,
						}}
					>
						<div
							style={{
								position: 'absolute',
								top: '50%',
								right: 0,
								left: 0,
								textAlign: 'center',
								color: 'grey',
								fontSize: 36,
							}}
						>
							<div>{translateString('upload.drop')}</div>
						</div>
					</div>
				)}
				{children}
			</div>
		);
	}
}
